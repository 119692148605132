<template>
  <div
    class="container"
    v-loading="loading"
    :element-loading-background="loadingBackground"
  >
    <!-- 调试用 -->
    <!-- <div class="test_container">
      <el-input v-model="feishuAppId"></el-input>
      <el-button @click="initRQcode">刷新二维码（调试用）</el-button>
    </div> -->
    <div class="contain">
      <div id="login">
        <div class="logins">
          <div class="logo_img">
            <!-- <svg-icon iconClass="logo" class="logo"></svg-icon> -->
            <img src="../../assets/images/Logo.png" alt="" class="logo" />
            <div class="logo_txt">工单系统</div>
          </div>
          <div class="change_login_type_content">
            <el-button
              type="text"
              :class="{ hover: loginType === 'FEISHU_QRCODE' }"
              @click="changeLoginType('FEISHU_QRCODE')"
            >
              扫码登录
            </el-button>
            |
            <el-button
              type="text"
              :class="{ hover: loginType === 'PASSWORD_LOGIN' }"
              @click="changeLoginType('PASSWORD_LOGIN')"
              >账号登录</el-button
            >
            |
            <el-button
              type="text"
              :class="{ hover: loginType === 'LDAP_LOGIN' }"
              @click="changeLoginType('LDAP_LOGIN')"
              >LDAP登录</el-button
            >
          </div>
          <div v-show="loginType === 'FEISHU_QRCODE'">
            <div id="login_container_codeRQ"></div>
            <div class="rqCode_note">
              <i class="e6-icon-scan"></i>&nbsp;打开飞书APP，扫一扫登录
              <el-button type="text" @click="initRQcode">刷新</el-button>
            </div>
          </div>
          <el-form
            ref="loginInfo"
            v-show="
              loginType === 'PASSWORD_LOGIN' || loginType === 'LDAP_LOGIN'
            "
            class="login-form"
            :model="loginInfo"
          >
            <el-form-item prop="userCode">
              <el-input
                v-on:keyup.enter.native="handleLogin"
                ref="loginFormUserCode"
                size="small"
                v-model="loginInfo.userCode"
                @keyup.enter.native="handleLogin"
                placeholder="请输入账号"
                clearable
              >
                <i slot="prefix" class="e6-icon-user_line"></i
              ></el-input>
            </el-form-item>
            <el-form-item prop="userPassword">
              <el-input
                ref="userPassword"
                @keyup.enter.native="handleLogin"
                type="userPassword"
                size="small"
                v-model="loginInfo.userPassword"
                placeholder="请输入密码"
                clearable
                show-password
                prefix-icon="e6-icon-password_line"
                @focus="handleClearMsg"
              >
                <!-- <i slot="prefix" class="e6-icon-password_line"></i> -->
              </el-input>
            </el-form-item>
            <!-- -->
            <el-form-item prop="verificationCode" v-show="isShowVerifyCode">
              <el-input
                style="width:230px;"
                placeholder="验证码"
                class="login-info__yzm"
                v-model="loginInfo.verificationCode"
                @keyup.enter.native="handleLogin"
              >
                <i slot="prefix" class="e6-icon-code_line"></i>
              </el-input>
              <div class="check_code">
                <img :src="vcSrc" @click="handleChangeVC" />
              </div>
            </el-form-item>
            <!-- <el-form-item>
              <el-checkbox class="save_account" v-model="loginInfo.saveAccount"
                >7天自动登录</el-checkbox
              >
            </el-form-item> -->
            <p class="login-form-error" v-show="errMsg !== ''">{{ errMsg }}</p>
            <el-form-item class="btn">
              <el-button
                class="button--login"
                size="small"
                @click="handleLogin"
              >
                {{ loginBtnText }}
              </el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { login, ldapLogin, getPhoneExt, qrCodeLogin } from "@/api";
//
import { printError, queryString } from "@/utils/util";
import base from "@/mixins/base";
// import websocketMinxins from "@/mixins/websocketMinxins";
const MD5 = require("md5.js");
import { b64_md5 } from "./b64_md5";
const feishuAppIdMap = {
  ["https://e3.e6haoyilian.com"]: "cli_a25faf53017bd00c", // 生产
  ["https://e3.e6gpshk.com"]: "cli_a255deb9083ad00b", // test
  ["https://e3aly.e6gpshk.com"]: "cli_a24ebe1139f1d00d", // dev
  ["http://localhost:8804"]: "cli_a24ebe1139f1d00d", // 本地 暂用会过期
  ["http://172.16.9.7:8804"]: "cli_a2525aeff779500b" // 本地 暂用会过期
};
const originURL = window.location.origin;

const feishuAppId = feishuAppIdMap[originURL];

const goto = `https://passport.feishu.cn/accounts/auth_login/oauth2/authorize?client_id=${feishuAppId}&redirect_uri=${originURL}&response_type=code&state=1655489411`;

export default {
  name: "login",
  mixins: [base],
  data() {
    return {
      loading: false, // 自动登录loading
      errMsg: "",
      feishuAppId, // 审批 testapp id
      QRLoginObj: null, // 飞书扫码登录对象
      loginType: "PASSWORD_LOGIN", // FEISHU_QRCODE: 飞书app扫码登录 FEISHU_APPLICATION：飞书客户端内置浏览器免登 PASSWORD_LOGIN: 密码登录
      loginInfo: {
        userCode: "", //登陆账号
        userPassword: "", //登陆密码
        loginSource: 0, //登录来源
        saveAccount: false,
        verificationCode: "" // 验证码
      },
      vcSrc: "", // 验证码 src
      isShowVerifyCode: false, // 是否展示验证码
      isLogin: false, // 是否登陆中
      loginBtnText: "登 录" // 登录按钮文字
    };
  },
  mounted() {
    // 这里做一个二维码生成判断 如果设置成默认密码登录 就不需要一开始就初始化二维码
    if (this.loginType === "FEISHU_QRCODE") {
      this.initRQcode();
    }
    //处理飞书扫码登录
    this.handleFeiShuLogin();
    //退出时判断不用ping
    let feishuNeedAutoLogin = this.$route.query.feishuNeedAutoLogin;
    let isRetry = Cookies.get("isRetry");
    // let E3RequestToken = Cookies.get("E3RequestToken");
    let isNeedPIng =
      (isRetry && isRetry != undefined) ||
      (feishuNeedAutoLogin && feishuNeedAutoLogin != undefined) ||
      originURL != "https://e3.e6haoyilian.com"
        ? false
        : true;
    console.log(isNeedPIng, "是否需要ping===");
    if (
      (!Cookies.get("E3RequestToken") ||
        Cookies.get("E3RequestToken") == undefined) &&
      isNeedPIng
    ) {
      const ldapMap = {
        //生产环境
        ["https://e3.e6haoyilian.com"]:
          "https://sso.chinawayltd.com/login?service=https%3A%2F%2Fe3.e6haoyilian.com%2Fapi%2FE3%2DBASE%2DLOGIN%2Fuser%2FldapCallBack", // 生产
        //测试环境
        ["https://e3.e6gpshk.com"]:
          "https://sso.test.chinawayltd.com/login?service=https%3A%2F%2Fe3.e6gpshk.com%2Fapi%2FE3%2DBASE%2DLOGIN%2Fuser%2FldapCallBack", // test
        ["https://e3aly.e6gpshk.com"]:
          "https://sso.test.chinawayltd.com/login?service=https%3A%2F%2Fe3.e6gpshk.com%2Fapi%2FE3%2DBASE%2DLOGIN%2Fuser%2FldapCallBack", // dev
        ["http://localhost:8804"]:
          "https://sso.test.chinawayltd.com/login?service=https%3A%2F%2Fe3.e6gpshk.com%2Fapi%2FE3%2DBASE%2DLOGIN%2Fuser%2FldapCallBack" // 本地 暂用会过期
      };
      const originURL = window.location.origin;
      const url = ldapMap[originURL];
      console.log(url, "==originURL");
      if (url && url != undefined) {
        this.ping(url);
      }
    } else {
      Cookies.remove("isRetry");
    }
  },
  methods: {
    // 截取url参数
    getValue() {
      let geturl = window.location.href;
      let obj = {}; //创建空对象，接收截取的参数
      if (geturl.split("?").length > 1) {
        let getqyinfo = geturl.split("?")[1];
        let getqys = getqyinfo.split("&");
        for (let i = 0; i < getqys.length; i++) {
          let item = getqys[i].split("=");
          let key = item[0];
          let value = item[1];
          if (i == getqys.length - 1) {
            obj[key] = value.split("#");
          } else {
            obj[key] = value;
          }
        }
      }
      return obj;
    },
    //处理飞书扫码登录逻辑
    handleFeiShuLogin() {
      // 监听扫码结果
      let handleMessage = event => {
        if (!this.QRLoginObj) {
          return;
        }
        const origin = event.origin;
        // 使用 matchOrigin 方法来判断 message 来自页面的url是否合法
        if (this.QRLoginObj.matchOrigin(origin)) {
          let loginTmpCode = event.data;
          // 在授权页面地址上拼接上参数 tmp_code，并跳转
          window.location.href = `${goto}&tmp_code=${loginTmpCode}`;
        }
      };
      if (typeof window.addEventListener != "undefined") {
        window.addEventListener("message", handleMessage, false);
      } else if (typeof window.attachEvent != "undefined") {
        window.attachEvent("onmessage", handleMessage);
      }
      // 扫码完成后 回调结束拿到code 直接走登录请求
      let query = queryString(window.location.search);
      if (query.code) {
        this.qrCodeLogin({
          code: query.code, // 验证code
          type: "FEISHU_QRCODE" // 扫码登录
        });
        return; // 如果内置浏览器扫码成功之后 不执行内置浏览器的自动登录
      }
      // 退出时加入的参数 值为1 其他情况为 undefined 仅作为对飞书
      let feishuNeedAutoLogin = this.$route.query.feishuNeedAutoLogin;

      if (window.h5sdk && !feishuNeedAutoLogin) {
        // 飞书内置浏览器
        this.loading = true;
        window.h5sdk.ready(() => {
          //  lark.ready参数为回调函数，在环境准备就绪时触发
          window.tt.requestAuthCode({
            appId: feishuAppId,
            success: info => {
              this.qrCodeLogin({
                code: info.code,
                type: "FEISHU_APPLICATION"
              });
              // this.$message.success(info.code);
              this.$message.success("获取飞书登录码成功，登录中，请稍后。。。");
            },
            fail: error => {
              this.$message.error(
                "获取自动登录CODE失败,请使用其他登录方式，错误码：" +
                  error.errCode
              );
              this.loading = false;
            }
          });
        });
      }
    },
    // 切换登录类型
    changeLoginType(loginType) {
      this.loginType = loginType;
      if (this.loginType === "FEISHU_QRCODE") {
        this.initRQcode();
      }
    },
    // 初始化二维码
    initRQcode() {
      this.QRLoginObj = null;
      document.getElementById("login_container_codeRQ").innerHTML = "";
      this.QRLoginObj = window.QRLogin({
        id: "login_container_codeRQ",
        goto: encodeURI(goto), // 需要进行url编码
        width: "260",
        height: "260",
        style: "width:260px;height:260px" //可选的，二维码html标签的style属性
      });
    },
    // 扫码登录或飞书内置浏览器登录
    async qrCodeLogin(data = {}) {
      try {
        this.loading = true;
        let res = await qrCodeLogin(data);
        if (res.code === "OK") {
          this.loginSucceseHandle(res.data);
          return;
        }
        this.$message.error(res.msg);
        // 扫码登录不成功 重新刷新二维码
        if (data.type === "FEISHU_QRCODE") {
          this.initRQcode();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //判断LDAP是否登录
    ping(url) {
      var img = new Image();
      var start = new Date().getTime();
      var flag = false;
      var isCloseWifi = true;
      var hasFinish = false;
      img.onload = function() {
        if (!hasFinish) {
          flag = true;
          hasFinish = true;
          if (
            !Cookies.get("E3RequestToken") ||
            Cookies.get("E3RequestToken") == undefined
          ) {
            //如果没有token并且登录了LDAP软件，直接进行免登操作
            window.location.href = url;
          }
          console.log("Ping " + url + " success. ");
        }
      };
      img.onerror = function() {
        if (!hasFinish) {
          if (!isCloseWifi) {
            flag = true;
            if (
              !Cookies.get("E3RequestToken") ||
              Cookies.get("E3RequestToken") == undefined
            ) {
              //如果没有token并且登录了LDAP软件，直接进行免登操作
              window.location.href = url;
            }
            console.log("Ping " + url + " success. ");
          } else {
            console.log("network is not working!");
          }
          hasFinish = true;
        }
      };
      setTimeout(function() {
        isCloseWifi = false;
        console.log("network is working, start ping...");
      }, 2);
      img.src = url + "/" + start;
      setTimeout(function() {
        if (!flag) {
          hasFinish = true;
          flag = false;
          console.log("Ping " + url + " fail. ");
        }
      }, 4000);
    },

    // 账号密码登录请求
    async loginReq(data) {
      try {
        this.loginBtnText = "登录中...";
        this.isLogin = true;
        //登录的时候清空通话id数组
        window.localStorage.removeItem("mainUniqueIdList");
        // let res = await login({ ...data, loginSource: 0 });
        let res =
          this.loginType === "PASSWORD_LOGIN"
            ? await login({ ...data, loginSource: 0 })
            : await ldapLogin({ ...data, loginSource: 0 });

        if (res.code !== "OK") {
          this.errMsg = res.msg;
          // this.loginBtnText = "登录";
          if (res.data && res.data.verificationCode) {
            this.handleChangeVC();
            this.isShowVerifyCode = true;
          } else {
            this.isShowVerifyCode = false;
          }
        } else {
          // 校验当前密码安全等级
          this.changeCookiesNeedEditPwd();
          this.loginSucceseHandle(res.data);
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loginBtnText = "登录";
      }
    },

    // 登录成功通用操作
    loginSucceseHandle(data = {}) {
      this.getPhoneExtReq();
      window.localStorage.removeItem("visitedViews"); // 移除visitedViews记录
      Cookies.set("E3RequestToken", data.token);
      console.log(data);
    },
    //登录成功获取坐席号
    async getPhoneExtReq() {
      try {
        let res = await getPhoneExt();
        Cookies.set("PhoneExt", res.data.phoneExt);
        window.location.href = "/";
      } catch (error) {
        printError(error);
      } finally {
        this.loginBtnText = "登 录";
        this.isLogin = false;
      }
    },
    // 登录验证
    handleLogin() {
      if (this.loginInfo.userCode === "") {
        this.errMsg = "请输入用户名";
      } else if (this.loginInfo.userPassword === "") {
        this.errMsg = "请输入密码";
      } else if (
        this.loginInfo.verificationCode === "" &&
        this.isShowVerifyCode
      ) {
        this.errMsg = "请输入验证码";
      } else {
        // new MD5().update(this.loginInfo.userPassword).digest("hex");
        let ps =
          this.loginType == "PASSWORD_LOGIN"
            ? new MD5().update(this.loginInfo.userPassword).digest("hex")
            : "{MD5}" + b64_md5(this.loginInfo.userPassword);
        let data = {
          userCode: this.loginInfo.userCode,
          userPassword: ps
        };
        if (this.isShowVerifyCode) {
          data.verificationCode = this.loginInfo.verificationCode;
        }
        this.loginReq(data);
      }
    },
    // 更换验证码
    handleChangeVC() {
      const LOGIN_HOST = process.env.VUE_APP_LOGIN_API || "";
      this.vcSrc = `${LOGIN_HOST}/api/E3-BASE-LOGIN/user/getVerification?tk=${new Date().getTime()}`;
    },
    // 清空错误信息
    handleClearMsg() {
      this.errMsg = "";
    },
    // 校验密码安全等级
    changeCookiesNeedEditPwd() {
      let safetyLevel = 0;
      let password = this.loginInfo.userPassword;
      if (/\d/.test(password)) safetyLevel++;
      if (/[a-zA-Z]+/.test(password)) safetyLevel++;
      if (/[^a-zA-Z0-9]+/.test(password)) safetyLevel++;
      // 密码安全等级为低时让用户必须修改密码
      if (safetyLevel <= 1) {
        Cookies.set("needEditPwd", true);
      } else {
        Cookies.remove("needEditPwd");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  background: url("~@/assets/images/loginBg.png") center center/100% no-repeat;
  background-size: cover;
  .test_container {
    position: absolute;
    top: 20px;
    left: 20px;
  }
  .contain {
    width: 600px;
    height: 530px;
    margin: 0 auto;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    .logins {
      width: 600px;
    }
    .logo_img {
      // width: 360px;
      text-align: center;
      .logo {
        width: 210px;
        // height: 50px;
      }
      .logo_txt {
        font-size: 28px;
        color: #ffffff;
        margin: 30px 0 0px;
      }
    }
    .change_login_type_content {
      text-align: center;
      color: #c0c5d1;
      height: 100px;
      /deep/ button {
        line-height: 80px;
        // width: 120px;
        margin-right: 15px;
        margin-left: 15px;
        // margin: 0px;
        height: 80px;
        color: #c0c5d1;
        cursor: pointer;
        display: inline-block;
      }
      button.hover {
        color: #ffffff;
        font-size: 18px;
        font-weight: bold;
        position: relative;
        &::after {
          display: block;
          height: 4px;
          width: 30px;
          content: "";
          background: #31b2ea;
          border-radius: 2px;
          position: absolute;
          bottom: 10px;
          transform: translateX(-50%);
          margin-left: 50%;
        }
      }
      /deep/ button:hover {
        color: #ffffff;
        font-weight: bold; //文字加粗英文字符加粗会抖动
      }
    }
    #login_container_codeRQ {
      background: #ffffff;
      width: 260px;
      overflow: hidden;
      margin: 0 auto;
      border-radius: 4px;
    }
    .rqCode_note {
      text-align: center;
      color: #ffffff;
      font-size: 14px;
      margin-top: 10px;
    }
    .login-form {
      display: flex;
      flex-direction: column;
      align-items: center;
      .login-form-error {
        text-align: center;
        font-size: 16px;
        color: #ff2828;
        margin-bottom: 10px;
      }

      i {
        font-size: 20px;
        line-height: 50px;
        margin-left: 5px;
      }
      /deep/ .el-input {
        width: 360px;
      }
      /deep/ input {
        width: 360px;
        height: 50px;
        line-height: 50px;
        border-radius: 0;
        padding-left: 40px;
        background-color: #ffffff;
        font-size: 15px;
      }
      /deep/ button {
        width: 360px;
        height: 50px;
        font-size: 24px;
        background-color: #31b2ea;
        color: #ffffff;
        border: 0;
        border-radius: 0;
      }
      /deep/ .login-info__yzm {
        input {
          width: 230px !important;
        }
      }
      /deep/ .save_account {
        font-size: 14px;
        color: rgba(255, 255, 255, 0.7);
        margin-bottom: 20px;
        .el-checkbox__inner {
          background-color: transparent;
          border-color: #31b2ea;
        }
        .el-checkbox__input.is-checked .el-checkbox__inner {
          background-color: transparent;
          border-color: #31b2ea;
        }
        .el-checkbox__input.is-checked .el-checkbox__inner::after {
          border-color: #31b2ea;
        }
      }
      /deep/ .el-form-item__error {
        padding-top: 3px;
      }
      .check_code {
        margin: 0px;
        float: right;
        padding: 0px;
        line-height: 0px;
        img {
          height: 50px;
          width: 120px;
        }
      }
    }
  }
}
.fix {
  zoom: 1;
}
/deep/.el-input__prefix {
  .e6-icon-password_line {
    font-size: 20px !important;
  }
}
</style>
